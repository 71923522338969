export interface ICreditCardTransactionLogModel {
    creditCardTransactionLogId: number;
    customerId: number;
    processorCustomerId: string;
    transactionDate: Date;
    userName: string;
    requestedAmount: number;
    authAmount: number;
    authCode: string;
    refNum: string;
    result: string;
    status: string;
    preAuthMessage: string;
    errorCode: string;
    errorMessage: string;
}
