import { IBuildProcessLogModel } from "./build-process-log.model";

export interface IBuildProcessModel {
    taskId: string;
    processName: string;
    startDate: Date;
    endDate?: Date;
    startedBy: string;

    logs: IBuildProcessLogModel[];
}