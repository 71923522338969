
export interface IEmployeeApptsReportModel {
	jobId: number;
	appointmentId: number;
	crewDisplay: string;
	scheduledDateTime: Date;
	inTransitStartTime?: Date;
	atJobsiteStartTime?: Date;
	finishedTime?: Date;
	jobName: string
	resourceName: string  
	scheduledMinutes: number;
	travelMinutes: number;
	goBack: boolean;
	appointmentDescription: string;
	appointmentCustomerNotes: string; 
	appointmentNotes: string;
	appointmentStatusDescription: string;
	appointmentTypeDescription: string;
	jobStatusDescription: string;
	jobTypeDescription: string;
	jobDescription: string;
	jobNotes: string;
	billToCustomerDisplayName: string;
	billToCustomerAddress: string;
	jobSiteCustomerDisplayName: string;
	jobSiteCustomerAddress: string;
}