import { ITimeclockDailySummaryModel } from "./timeclock-daily-summary.model";

export interface ITimeclockWeeklySummaryModel {
	userId: number;
	userFullName: string;
	weekStarting: Date;
	weekEnding: Date;
	totalMinutes: number;
	totalHours: number;
	totalDisplay: string;
	totalWorkedMinutes: number;
	totalWorkedHours: number;
	totalWorkedDisplay: string;
	totalBreakMinutes: number;
	totalBreakHours: number;
	totalBreakDisplay: string;
	regularPayTotalMinutes: number;
	regularPayTotalDisplay: string;
	overtimePayTotalMinutes: number;
	overtimePayTotalDisplay: string;

	dailySummaries: ITimeclockDailySummaryModel[];
}

