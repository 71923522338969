import { ILathamOrderInsightsBuilderOrderSummaryModel } from "./latham-order-insights-builder-order-summary.model";

export interface ILathamOrderInsightsBuilderModel {
	builderId: number;
	builderName: string;
	distributorName: string;
	numberOfAtom: number;
	numberOfEclipse: number;
	numberOfCS300: number;
	numberOfMiniInfinity: number;
	numberOfCS3000: number;
	numberOfInfinity: number;
	numberOfReplacementFabric: number;
	numberOfCS500: number;
	numberOfCS1000: number;
	numberOfCS1500: number;
	numberOfCS2000: number;
	numberOfManualTrak: number;
	numberOfPowerTrak: number;
	numberOfSerenity: number;
	numberOfParts: number;
	currentYearSales: number;
	previousYTDSales: number;
	twoYearsYTDSales: number;
	previousYearSales: number;
	twoYearsSales: number;

	lathamOrderInsightsBuilderOrderSummaries: ILathamOrderInsightsBuilderOrderSummaryModel[];
}