import { ITimeclockEntryModel } from './timeclock-entry.model';

export interface ITimeclockQuickbooksEmployeeDetailModel {
	weekStartDate: Date;
	jobSyncedToQuickbooks: boolean;
	jobQuickbooksName: string;
	payRateType: string;
	className: string;

	errors: string[];
	errorsDisplay: string;

	dailyHours: number[];
	timeclockEntries: ITimeclockEntryModel[];
}

