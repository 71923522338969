export interface IInvoiceGridModel {
    invoiceId: number;
    companyId: number;
    invoiceDateDisplay: string;
    invoiceDate: Date;
    invoiceDueDate: Date;
    quoteDate: Date;
    daysPastDue: number;
    isQuote: boolean;
    isDeleted: boolean;
    invoiceNumber: string;
    purchaseOrderNumber: string;
    invoiceStatusId: number;
    invoiceStatusName: string;
    paymentTermId: number;
    paymentTermName: string;
    quickbooksSyncStatus: string;
    businessCentralSyncStatus: string;
    acceptedDate: Date;
    acceptedSignature: string;
    salesRepUserId?: number;
    salesRepFullName: string;
    firstName: string;
    lastName: string;
    companyName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    invoiceTotal: number;
    appliedPaymentsTotal: number;
    invoiceTotalDisplay: string;
    appliedPaymentsTotalDisplay: string;
    displayName: string;
    outstandingBalance: number;
    outstandingBalanceDisplay: string;
    batch: boolean;
    customerId: number;
}

