export interface IInvoiceListModel {
    invoiceId: number;
    companyId: number;
    invoiceDate: Date;
    invoiceDueDate: Date;
    quoteValidUntilDate?: Date;
    quoteDate: Date;
    isQuote: boolean;
    isSalesOrder: boolean;
    isDeleted: boolean;
    invoiceNumber: string;
    purchaseOrderNumber: string;
    invoiceStatusId: number;
    invoiceStatusName: string;
    syncToQuickbooks: boolean;
    quickbooksId: string;
    acceptedDate: Date;
    acceptedSignature: string;
    salesRepUserId?: number;
    salesRepFullName: string;
    firstName: string;
    lastName: string;
    companyName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    fullName: string;
    displayName: string;
    invoiceTotal: number;
    appliedPaymentsTotal: number;
    outstandingBalance: number;
}

