export interface IItemListViewModel {
	itemId: number;
	sku: string;
	description: string;
	imageThumbnailUrl: string;
	quickbooksId: string;
	syncToQuickbooks?: boolean;
	quickbooksSyncStatus: string;
	active: boolean;
}
