import { CustomerModel, ICustomerModel } from "@models/customers/customer.model";

export interface ILathamOrderGridModel {
	lathamOrderId: number;
	orderId: string;
	orderStatus: string;
	orderType: string;
	purchaseOrderNumber: string;
	quoteDate: Date;
	modelType: string;
	displayName: string;
	submittedBy: string;
	submittedDate: Date;
	quoteAcceptedDate: Date;
	homeOwner: ICustomerModel;
	builder: ICustomerModel;
	lathamDueDate: Date;
	lathamShipDate: Date;
	lathamOrderStatus: string;
	lathamProNumber: string;
	hasShipped: boolean;
	batch: boolean;
}
