import { ITaxRateModel } from "./tax-rate.model";

export interface ITaxRateGroupModel {
	taxRateGroupId: number;
	taxRateGroupName: string;
	quickbooksId: string;
	quickbooksName: string;
	syncToQuickbooks: boolean;

	taxRates: ITaxRateModel[];
}
