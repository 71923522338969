export interface IBusinessCentralLocationModel {
	id: string,
	code: string,
	displayName: string,
	contact: string,
	addressLine1: string,
	addressLine2: string,
	city: string,
	state: string,
	country: string,
	postalCode: string,
	phoneNumber: string,
	email: string,
	website: string
}
