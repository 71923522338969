import { ILineItemModel } from "@models";

export interface IReceivedLineItemModel extends ILineItemModel {
	inventoryAdjustmentId: number;
	inventoryWarehouseId: number;
	inventoryWarehouseName: string;
	inventoryItemLocationId?: number;
	inventoryLocationId?: number;
	inventoryItemLocationName: string;
	received: boolean;
	receivedQuantity: number;
	receivedDate?: Date;
	receivedBy: string;
}