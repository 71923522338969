export interface IPayPeriodQuickbooksDetailModel {
	payPeriodQuickbooksDetailId: number;
	quickbooksID: string;
	uuid: string;
	txnID: string;
	quickbooksStatus: string;
	quickbooksMessage: string;
	txnDate: string;
	entityFullName: string;
	customerFullName: string;
	itemServiceFullName: string;
	duration: string;
	payrollItemWageFullName: string;
}

