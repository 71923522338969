import { ILocationModel } from "../lookups/location.model";

export interface ICompanyModel {
	companyId: number;
	parentCompanyId?: number;
	defaultJobTypeId?: number;
	startPayDate?: Date;
	documentsFolderUuid: string;
	companyName: string;
	companyCode: string;
	locations: ILocationModel[];
	firstName: string;
	lastName: string;
	address1: string;
	address2: string;
	city: string;
	state: string;
	zipcode: string;
	country: string;
	phoneNumber: string;
	contactPhone: string;
	email: string;
	userName: string;
	simpleOrdering: boolean;
	cs3000Only: boolean;
	canOrderCS3000b: boolean;
	canOrderEclipseb: boolean;
	canOrderIN4000b: boolean;
	lathamArchiveOrderStates: string;
	lathamArchiveOrderAcronym: string;
	orderingType: string;
	taxRate: number;
	useCereTax: boolean;
	flagNonSignedServiceAgreement: boolean;
	autoSendServiceAgreement: boolean;
	serviceAgreementHtml: string;
	useCustomInvoiceHeader: boolean;
	invoiceHeaderHtml: string;
	invoiceHeaderHeight?: number;
	invoiceFooterHtml: string;
	invoiceFooterHeight?: number;
	invoiceCustomField1Name: string;
	invoiceCustomField2Name: string;
	invoiceCustomField3Name: string;
	invoiceCustomField4Name: string;
	downPaymentType?: number;
	defaultDownPaymentPercent?: number;
	defaultDownPaymentAmount?: number;
	largeScheduleRows: boolean;
	showAppointmentDescriptionOnSchedule: boolean;
	geoTrackingEnabled: boolean;
	useAdvancedTax: boolean;
	useAcuity: boolean;
	useAppointmentReminder: boolean;
	taxRate1Name: string;
	taxRate2Name: string;
	taxRate3Name: string;
	outgoingEmailDomain: string;
	isPaid: boolean;
	isQPro: boolean;
	isLathamOrdersOnly: boolean;
	useJellyFishOrdering: boolean
	isJellyFishTrial: boolean;
	quickbooksOnlineEnabled: boolean;
	quickbooksWebConnectorEnabled: boolean;
	businessCentralEnabled: boolean;
	zapierEnabled: boolean;
	quickbooksCustomerLastCommaFirst: boolean;
	lockInvoiceNumbers: boolean;
	lockInvoicePONumbers: boolean;
	lockInvoiceDates: boolean;
	lockInvoiceTaxRates: boolean;
	lockInvoicePaymentTerms: boolean;
	lockInvoiceNotes: boolean;
	useLabor2LineItem: boolean;
	useLabor3LineItem: boolean;
    useLabor4LineItem: boolean;
    useLabor5LineItem: boolean;
	lathamOrderDirectToInvoice: boolean;
	hideSpecsOnLathamPDF: boolean;
	autoGeneratePOFromLathamOrder: boolean;
	additionalLocations: boolean;
	adminOnlyTimeclockEdit: boolean;
	showTimeClockPerDiem: boolean;
	showTimeClockNotes: boolean;
	showClockInReminders: boolean;
	useAppointmentStrikes: boolean;
	useOneTimeAddress: boolean;
	defaultToJobCustomer: boolean;
	showPaymentTypeInCustomerInfo: boolean;
	showCustomerNotes: boolean;
	useCreditCardProcessor: boolean;
	useUSAePay: boolean;
	useBambora: boolean;
	usePayTrace: boolean;
	useZift: boolean;
	useFuelSurcharge: boolean;
	fuelSurchargeType: number;
	useInventory: boolean;
	useShipping: boolean;
	useBarCodes: boolean;
	useDailyPreChecklist: boolean;
	useQuoteAcceptance: boolean;
	useCustomSKU: boolean;
	allowTips: boolean;
	parentCompanyName: string;
	featuresPriceLevelId: number;
	dailyEmailSendTime?: Date;
	lastDailyEmail?: Date;
	lathamAccessKey: string;
	useLathamOrdering: boolean;
	showLathamItemsInItemSearch: boolean;
	useLathamDiscount: boolean;
	lathamDiscountAmount?: number;
	lathamDiscountEndDate?: Date;
	timeZone: string;
	receiptPopUpAfterPayment: boolean;
	customerQuickbooksAutoSync
	requirePhotosForAppt: boolean;
	useSkeditPay: boolean;
	customerAdditionalAddresses: boolean;
	active: boolean;
	lathamOrderDiscount: number;
	trialStartDate: Date;
	freeTrialEndDate: Date;
	trialDays: number;
	daysLeftInTrial: number;
	activeUsers: number;
	numberOfUsers: number;
	hideInvoiceQuantity: boolean;
	hideInvoicePrice: boolean;
	noTaxIdNotification: boolean;
	customerAttentionNoteNotification: boolean;
	customerAttentionNotePopUp: boolean;
    logoUrl: string;
    useItemGroupings: boolean;
	useAdvancedHours: boolean;
	lathamCustomerType: string;
	isLathamDistributor: boolean;
	hasChildCompanies: boolean;
	paymentTerms: string;
	paymentAmount?: number;
	lastPaymentDate?: Date;
	nextPaymentDate?: Date;
	useOnlinePayments: boolean;
	onlinePaymentNotificationEmailAddress: string;
	onlinePaymentShowSummaryOnly: boolean;
	onlinePaymentNote: string;
	useSendGrid: boolean;
	useUserEmailAddress: boolean;
	companyEmailDomain: string;
	accountingEmailAddress: string;
	accountingEmailFromName: string;
	serviceEmailAddress: string;
	serviceEmailFromName: string;
	useTextChat: boolean;
	textChatPhoneNumber: string;
	textChatPhoneNumberDisplay: string;
	sendEnhancementMessages: boolean;
	sendServiceAgreeementToUnsignedCustomers: boolean;
	sendServiceAgreementFrequencyId?: number;
}


