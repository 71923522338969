import { ITimeOffDetailModel } from './time-off-detail.model';

export interface ITimeOffSummaryModel {
	totalTimeOff: number;
	totalPTO: number;
	totalNonPTO: number;
	totalAdjustments: number;
	ptoRemaining: number;

	timeOffDetails: ITimeOffDetailModel[];
}
