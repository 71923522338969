import { Component, ViewChild, Output, Input, EventEmitter } from "@angular/core";
import { SlickConfirmDialogComponent, SlickDialogComponent, SlickScreenBlockerService } from "@slick-components";
import { ICustomerModel, ICreditCardPaymentTypeModel, CreditCardPaymentTypeModel, ICreditCardProcessPaymentModel, IInvoiceListModel, ICreditCardTransactionLogModel, ICreditCardPaymentApprovedModel, CreditCardPaymentApprovedModel, ICustomerListModel, CustomerModel } from "@models";
import { GlobalsService, PaymentsService, CreditCardService, SleepService, CustomersService, UtilsService, LookupService } from "@services";
import { EmailerDialogComponent } from "@components/emailer-dialog";

import { CreditCardEditDialogComponent } from "./credit-card-edit-dialog/credit-card-edit-dialog.component";
import { USAePayService } from "../../../../libraries/services/credit-card-processing";


@Component({
    selector: "credit-card-dialog",
    templateUrl: "./credit-card-dialog.component.html",
    styleUrls: ["./credit-card-dialog.component.css"],
	providers: [SlickScreenBlockerService, PaymentsService, CreditCardService, CustomersService]
})

export class CreditCardDialogComponent {
	@Output() onApproved: EventEmitter<ICreditCardPaymentApprovedModel> = new EventEmitter();
	@Output() onChildPaymentApproved: EventEmitter<ICreditCardPaymentApprovedModel> = new EventEmitter();
	@Output() onPaymentTypesChanged: EventEmitter<ICreditCardPaymentTypeModel[]> = new EventEmitter();
	@Input() searchText: string;
	@Input() useCompanyId: number;
	@Input() amountLocked: boolean = false;

	@ViewChild("customerEditDialogRef", { static: true }) customerEditDialogRef: SlickDialogComponent;
    @ViewChild("creditCardDialogRef", { static: true }) creditCardDialogRef: SlickDialogComponent;
	@ViewChild("creditCardEditDialog", { static: true }) creditCardEditDialog: CreditCardEditDialogComponent;
	@ViewChild("emailerDialogRef") emailerDialogRef: EmailerDialogComponent;
	@ViewChild("cardNotFound") cardNotFound: SlickConfirmDialogComponent;
	customerModel: ICustomerModel;
	newCustomerModel: ICustomerModel;
	selectedPaymentTypeModel: ICreditCardPaymentTypeModel;
	creditCardProcessPaymentModel: ICreditCardProcessPaymentModel;
	creditCardTransactionLogModel: ICreditCardTransactionLogModel;
	prePaymentSpinner: string;
	paymentSpinner: string;
	isQuickbooksEnabled = GlobalsService.isQuickbooksEnabled;
	isCanada = (GlobalsService.company.companyId === 3);
	syncToQuickbooks: boolean;
	customerInvoices: IInvoiceListModel[];
	approvedPaymentUuid: string;
	spinnerStatus: string;
	isSubmitted: boolean;
	paymentApproved: boolean;

	useSkeditPay: boolean = GlobalsService.company.useSkeditPay;

	creditCardPaymentApprovedModel: ICreditCardPaymentApprovedModel;
	creditCardPaymentTypes: ICreditCardPaymentTypeModel[];

	results: ICustomerListModel[];
	selectedCustomerBasic: ICustomerListModel;

	displayCustomerSearch: boolean = false;

	countries: any = [
		{ id: 'US', text: 'US' },
		{ id: 'Canada', text: 'Canada' },
		{ id: 'Mexico', text: 'Mexico' },
		{ id: 'Israel', text: 'Israel' },
	]

	constructor(private customersService: CustomersService,
		private lookupService: LookupService,
		private paymentsService: PaymentsService,
		private slickScreenBlockerService: SlickScreenBlockerService,
		private creditCardService: CreditCardService,
		private USAePayService: USAePayService) {
	}

	async openDialog(customerModel: ICustomerModel = null, creditCardProcessPaymentModel: ICreditCardProcessPaymentModel = null, selectedCreditCardPaymentTypeId: number = null) {
		if (!customerModel) {
			this.displayCustomerSearch = true;
			this.searchText = '';
		}
		this.paymentApproved = false;
		this.paymentSpinner = "reset";
		this.prePaymentSpinner = "reset";

		this.creditCardPaymentTypes = null;
		this.creditCardTransactionLogModel = null;
		this.selectedPaymentTypeModel = null;
		this.syncToQuickbooks = true;

		this.customerModel = customerModel;
		if (creditCardProcessPaymentModel) {
			this.creditCardProcessPaymentModel = creditCardProcessPaymentModel;
			if(customerModel)
				this.creditCardProcessPaymentModel.customerId = customerModel.customerId;
		}

		// Get any open invoices for this customer
		//if (!customerModel || this.customerModel.customerId === 0)
		//	this.customerInvoices = [];
		//else
		//	this.customerInvoices = await this.invoicesService.getInvoicesByCustomer(this.customerModel.customerId, true);

		if (this.customerModel?.customerId > 0) {
			//special code for jellyfish. We need to use whatever jellyfish is using
			if (GlobalsService.company.parentCompanyId === 133 && (customerModel.companyId != GlobalsService.company.companyId)) 
				this.creditCardPaymentTypes = await this.USAePayService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);
			else
				this.creditCardPaymentTypes = await this.creditCardService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);		

		}

		this.creditCardDialogRef.showDialog();

		if (this.creditCardPaymentTypes?.length > 0) {
			if (selectedCreditCardPaymentTypeId) {
				this.selectedPaymentTypeModel = this.creditCardPaymentTypes.find(x => x.creditCardPaymentTypeId === selectedCreditCardPaymentTypeId);
				if (!this.selectedPaymentTypeModel) {
					this.cardNotFound.confirm();
					this.selectedPaymentTypeModel = this.creditCardPaymentTypes[0];
				}
			}
			else {
				this.selectedPaymentTypeModel = this.creditCardPaymentTypes.find(x => x.isDefault === true);
				if (!this.selectedPaymentTypeModel)
					this.selectedPaymentTypeModel = this.creditCardPaymentTypes[0];
			}
		}
    }

    async creditCardEditDialogChanges() {
        // Something has changed
		if (GlobalsService.company.parentCompanyId === 133 && (this.customerModel.companyId !== GlobalsService.company.companyId))
			this.creditCardPaymentTypes = await this.USAePayService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);
		else
			this.creditCardPaymentTypes = await this.creditCardService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);

		if (this.creditCardPaymentTypes && this.creditCardPaymentTypes.length > 0)
			this.selectPaymentType(this.creditCardPaymentTypes[0]);

        this.onPaymentTypesChanged.emit(this.creditCardPaymentTypes);
    }

    createNewPaymentType() {
		const paymentType = new CreditCardPaymentTypeModel();
		paymentType.addressModel.address1 = this.customerModel.address1
		paymentType.addressModel.address2 = this.customerModel.address2
		paymentType.addressModel.city = this.customerModel.city
		paymentType.addressModel.state = this.customerModel.state
		paymentType.addressModel.zipcode = this.customerModel.zipcode
		paymentType.addressModel.country = this.customerModel.country
		paymentType.customerId = this.customerModel.customerId;
        this.creditCardEditDialog.openDialog(paymentType, true, this.customerModel.companyId);
	}

	selectPaymentType(paymentType: ICreditCardPaymentTypeModel) {
		this.selectedPaymentTypeModel = paymentType;
	}

    editPaymentType(paymentType: ICreditCardPaymentTypeModel) {
        this.creditCardEditDialog.openDialog(paymentType, false, this.customerModel.companyId);
	}

	async processPrePayment() {
		try {
			this.prePaymentSpinner = "spin";
			this.creditCardProcessPaymentModel.creditCardPaymentTypeId = this.selectedPaymentTypeModel.creditCardPaymentTypeId;
			this.creditCardProcessPaymentModel.syncToQuickbooks = this.syncToQuickbooks;
			const creditCardProcessPaymentResult = await this.creditCardService.processPrePayment(this.creditCardProcessPaymentModel);

			this.creditCardTransactionLogModel = creditCardProcessPaymentResult.creditCardTransactionLogModel;
			
			if (this.creditCardTransactionLogModel.result === 'Approved' || this.creditCardTransactionLogModel.result === 'A01') {
				this.paymentApproved = true;
				const creditCardPaymentApprovedModel: ICreditCardPaymentApprovedModel = new CreditCardPaymentApprovedModel();
				creditCardPaymentApprovedModel.creditCardTransactionLogModel = creditCardProcessPaymentResult.creditCardTransactionLogModel;

				if (this.onApproved)
					this.onApproved.emit(creditCardPaymentApprovedModel);

				this.prePaymentSpinner = "ok";
			}
			else {
				this.paymentApproved = false;
				this.prePaymentSpinner = "error";
			}
		}
		catch (err) {
			this.prePaymentSpinner = "error";
		}
	}

	async processPayment() {
		try {
			this.paymentSpinner = "spin";
			this.creditCardProcessPaymentModel.creditCardPaymentTypeId = this.selectedPaymentTypeModel.creditCardPaymentTypeId;
			this.creditCardProcessPaymentModel.syncToQuickbooks = this.syncToQuickbooks;
			
			// special code for jellyfish, use usaEpay if they are submitting to jellyfish and need a payment
			const creditCardProcessPaymentResult = GlobalsService.company.parentCompanyId === 133
				&& (this.customerModel.companyId != GlobalsService.company.companyId)
				? await this.USAePayService.processPayment(this.creditCardProcessPaymentModel)
				: await this.creditCardService.processPayment(this.creditCardProcessPaymentModel);

			this.creditCardTransactionLogModel = creditCardProcessPaymentResult.creditCardTransactionLogModel;
			//A01 is approved for zift
			if (this.creditCardTransactionLogModel.result === 'Approved' || this.creditCardTransactionLogModel.result === 'A01') {
				this.paymentApproved = true;
				this.creditCardPaymentApprovedModel = new CreditCardPaymentApprovedModel();
				this.creditCardPaymentApprovedModel.creditCardTransactionLogModel = creditCardProcessPaymentResult.creditCardTransactionLogModel;
				this.creditCardPaymentApprovedModel.paymentModel = creditCardProcessPaymentResult.paymentModel;
				this.creditCardPaymentApprovedModel.appliedPayment = creditCardProcessPaymentResult.appliedPaymentModel;
				this.approvedPaymentUuid = this.creditCardPaymentApprovedModel.appliedPayment?.uuid;

				if (GlobalsService.company.parentCompanyId === 133 && (this.customerModel.companyId != GlobalsService.company.companyId))
					this.onChildPaymentApproved.emit(this.creditCardPaymentApprovedModel);
				if (this.onApproved)
					this.onApproved.emit(this.creditCardPaymentApprovedModel);
				this.paymentSpinner = "ok";
				
			}
			else {
				this.paymentApproved = false
				this.paymentSpinner = "error";
			}
		}
		catch (err) {
			this.paymentSpinner = "error";
		}
	}

	async printAppliedPaymentReceipt() {
		const w = window.open('', '_blank');
		w.document.write('Generating pdf...');
		const url = await this.paymentsService.generateInvoicePaymentReceiptPdf(this.approvedPaymentUuid);
		w.location.href = url;
	}

	async generatePaymentPdf() {
		const w = window.open('', '_blank');
		w.document.write('Generating pdf...');
		const url = await this.paymentsService.generatePaymentReceiptPDF(this.creditCardPaymentApprovedModel.paymentModel.paymentId);
		w.location.href = url;
	}

	closeDialog() {
		if (this.displayCustomerSearch)
			this.customerModel = null;
		this.searchText = '';
        this.creditCardDialogRef.hideDialog();
	}

	async onApprovedOk() {
		this.creditCardDialogRef.hideDialog();
		if (GlobalsService.company.receiptPopUpAfterPayment) {
			this.slickScreenBlockerService.block();
			const emailerModel = await this.paymentsService.preparePaymentReceiptEmail(this.creditCardPaymentApprovedModel.paymentModel);
			this.slickScreenBlockerService.unblock();
			this.emailerDialogRef.showDialog(emailerModel);
		}
		else {
			await this.paymentsService.emailPaymentReceipt(this.creditCardPaymentApprovedModel.paymentModel);
        }
	}

	async onKeyUp(e: KeyboardEvent) {
		await SleepService.sleep();
		if (!this.searchText && this.customerModel) {
			this.customerModel = null;
		}
	}

	async onBlur() {
		await SleepService.sleep();
		if (!this.searchText && this.customerModel) {
			this.customerModel = null;
		}
	}

	async search(searchText) {
		this.results = await this.customersService.getCustomersForList(searchText, false, false, false, this.useCompanyId);
	}

	async onSelect(customer: ICustomerListModel) {
        this.selectedCustomerBasic = customer;
        this.customerModel = await this.customersService.getCustomer(customer.customerId);
        this.searchText = this.customerModel.companyName && this.customerModel.companyName.length > 0
            ? this.customerModel.companyName
			: this.customerModel.fullName;
		this.creditCardProcessPaymentModel.customerId = customer.customerId;
		this.creditCardPaymentTypes = await this.creditCardService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);
		if (this.creditCardPaymentTypes?.length > 0) {
			this.selectedPaymentTypeModel = this.creditCardPaymentTypes.find(x => x.isDefault === true);
			if (!this.selectedPaymentTypeModel)
				this.selectedPaymentTypeModel = this.creditCardPaymentTypes[0];
		}
	}


	async showNewCustomerDialog() {
		this.newCustomerModel = new CustomerModel();
		await this.customerEditDialogRef.showDialog();
	}

	async showEditCustomerDialog() {
		this.newCustomerModel = UtilsService.clone(this.customerModel);
		await this.customerEditDialogRef.showDialog();
	}


	isValid(): boolean {
		let isValid = true;

		if (!this.newCustomerModel.firstName && !this.newCustomerModel.lastName && !this.newCustomerModel.companyName)
			isValid = false;

		return isValid;
	}

	async saveCustomer() {
		this.customerModel = this.newCustomerModel;
		this.isSubmitted = true;
		if (this.isValid() === false) {
			this.spinnerStatus = "error";
			return;
		}

		try {
			this.spinnerStatus = "spin";
			await SleepService.sleep(500);

            if (!this.customerModel.customerId) {
                this.customerModel.customerTypeId = this.lookupService.getCustomerTypes().find(x => x.name === 'Home Owner').customerTypeId;
                this.customerModel.priceLevelId = this.lookupService.getPriceLevels().find(x => x.name === 'Default').priceLevelId;
                this.customerModel.paymentTermId = this.lookupService.getPaymentTerms().find(x => x.name === 'COD').paymentTermId;

                this.customerModel = await this.customersService.addCustomer(this.customerModel);

			}
			else
				this.customerModel = await this.customersService.updateCustomer(this.customerModel);
			
			this.spinnerStatus = "ok";
			this.searchText = this.customerModel.companyName && this.customerModel.companyName.length > 0
				? this.customerModel.companyName
				: this.customerModel.fullName;
			this.customerEditDialogRef.hideDialog();
		} catch (err) {
			this.spinnerStatus = "error";
		}

	}
	async cancelCustomer() {
		this.customerEditDialogRef.hideDialog();
    }
}