export interface ITimeclockUserGridModel {
	userId: number;
	userFullName: string;
	currentJob: string;
	unapprovedEntries: number;
	totalOvertimeDisplay: string;
	totalBreakDisplay: string;
	totalHolidayDisplay: string;
	totalPTODisplay: string;
	totatUTODisplay: string;
	hasError: boolean;
	htmlErrorMessages: string;
}