import { Component, ViewChild } from '@angular/core';
import { DropdownModel, ICustomerModel, IDropdownModel, IPaymentModel, PaymentModel } from '@models';
import { SleepService, PaymentsService, LookupService, GlobalsService } from '@services';

import { SlickDialogComponent } from "@slick-components";

@Component({
	selector: 'add-payment-dialog',
	templateUrl: './add-payment-dialog.component.html',
	providers: [PaymentsService]
})
export class AddPaymentDialogComponent {
	@ViewChild("addPaymentDialogRef") addPaymentDialogRef: SlickDialogComponent;

	isQuickbooksEnabled = GlobalsService.isQuickbooksEnabled;
	isBusinessCentralEnabled = GlobalsService.isBusinessCentralEnabled;

	paymentModel: IPaymentModel;
	resolve: any;

	paymentTypes: IDropdownModel[] = [];
	isSubmitted: boolean;
	spinnerStatus: string;

	sendToQuickbooks: boolean;

	constructor(private paymentsService: PaymentsService) {
		this.paymentTypes = [];
		this.paymentTypes.push(new DropdownModel("Cash", "Cash"));
		this.paymentTypes.push(new DropdownModel("Check", "Check"));
		this.paymentTypes.push(new DropdownModel("American Express", "American Express"));
		this.paymentTypes.push(new DropdownModel("Discover", "Discover"));
		this.paymentTypes.push(new DropdownModel("Mastercard", "Mastercard"));
		this.paymentTypes.push(new DropdownModel("Visa", "Visa"));
		this.paymentTypes.push(new DropdownModel("e-transfer", "e-transfer"));
		this.paymentTypes.push(new DropdownModel("ACH/EFT", "ACH/EFT"));
		this.paymentTypes.push(new DropdownModel("Wire Transfer", "Wire Transfer"));
		this.paymentTypes.push(new DropdownModel("Other", "Other"));
	}

	async showDialog(customer: ICustomerModel, paymentModel: IPaymentModel = null): Promise<IPaymentModel> {

		return new Promise<IPaymentModel>((resolve) => {
			this.resolve = resolve;

			this.isSubmitted = false;
			this.spinnerStatus = "reset";
			this.sendToQuickbooks = true;

			this.paymentModel = paymentModel;

			if (!this.paymentModel) {
				this.paymentModel = new PaymentModel();
				this.paymentModel.customer = customer;
			}

			this.addPaymentDialogRef.showDialog();
		})
	}

	validate(): boolean {
		if (!this.paymentModel.paymentType)
			return false;

		if (!this.paymentModel.paymentDate)
			return false;

		if (!this.paymentModel.amount)
			return false;
		return true;
	}

	async onSave() {
		this.isSubmitted = true;
		if (!this.validate()) {
			this.spinnerStatus = "error";
			return;
		}

		this.spinnerStatus = "spin";
		await SleepService.sleep(500);
		try {
			if (this.isQuickbooksEnabled && this.sendToQuickbooks === true)
				this.paymentModel.syncToQuickbooks = true;

			this.paymentModel = await this.paymentsService.updatePayment(this.paymentModel);
			
			//seperating this out to fix qb errors being thrown and causing the payment model to not be returned.
			if ((this.isQuickbooksEnabled && this.paymentModel.syncToQuickbooks === true) || (this.isBusinessCentralEnabled && this.paymentModel.syncToBusinessCentral === true)) {
				this.paymentModel = await this.paymentsService.syncToQuickbooks(this.paymentModel.paymentId);
			}

			this.spinnerStatus = "ok";
			await SleepService.sleep(1000);

			this.addPaymentDialogRef.hideDialog();
			this.resolve(this.paymentModel);
		}
		catch {
			this.spinnerStatus = "error";
		}
	}

	onCancel() {
		this.addPaymentDialogRef.hideDialog();
		this.resolve(null);
	}
}

