import { IUserPermissionModel } from "./user-permission.model";
import { ILayoutSettings } from "../settings/layout-settings.model";

export interface IUserInfoModel {
    userId: number;
	userName: string;
	firstName: string;
	lastName: string;
	fullName: string;
	roleTypeId: number;
	defaultLocationId?: number;
	defaultWarehouseId?: number;
	isDisabled: boolean;
	isGlobalAdmin: boolean;
	profilePictureUrl: string;
	theme: string;
    userPermissions: IUserPermissionModel[];
    layoutSettings: ILayoutSettings;
    allowedLocationIds: number[];
    selectedLocationIds: number[];
	timeClockExempt: boolean;
	geoTrackingExempt: boolean;
	showAppointmentBorder: boolean;
	showOnChat: boolean;
	isSalesRep: boolean;
	showAllQBWCErrors: boolean;
	usePushNotifications: boolean;
	lastSeenVersion: string;
	emailAddress: string;
	phoneNumber: string;
}
