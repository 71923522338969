export interface IPaymentListViewModel {
    paymentId: number;
    companyId: number;
    paymentType: string;
    referenceNumber: string;
    paymentDate: Date;
    amount: number;
    creditCardInfo: string;
    notes: string;
    firstName: string;
    lastName: string;
    companyName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    fullName: string;
    displayName: string;
    paymentDateDisplay: string;
    amountDisplay: string;
    appliedPaymentAmount: number;
    appliedPaymentAmountDisplay: string;
    totalRemainingAmount: number;
    totalRemainingAmountDisplay: string;
    isOnlinePayment: boolean;
    isDownPayment: boolean;
    amountRefunded?: number;
    quickbooksSyncStatus: string;
    businessCentralSyncStatus: string;
}
