export interface IQBWCTaskModel {
	qbwcTaskId: number;
	ticketId: string;
	request: string;
	requestedBy: string;
	requestDate: Date;
	requestId: string;
	requestSentDate?: Date;
	requestXML: string;
	responseReceivedDate?: Date;
	responseXML: string;
	isResponseTruncated: boolean;
	status: string;
	errorMessage: string;
	isPending: boolean;
	isHighPriority: boolean;
	customField: string;
	customDisplayInfo: string;
	displayErrorNotification?: boolean;
	displayErrorClearedBy: string;
	displayErrorClearedDate?: string;
	xmlFileName: string;
}
