export class DiscountTrackingModel implements IDiscountTrackingModel {
    discountTrackingId: number;
    date: Date;
    userFullName: string;
    discount: string;
    lathamOrderId: string;
}

export interface IDiscountTrackingModel {
    discountTrackingId: number;
    date: Date;
    userFullName: string;
    discount: string;
    lathamOrderId: string;
}

   
