import { ITimeclockQuickbooksEmployeeDetailModel } from './timeclock-quickbooks-employee-detail.model';

export interface ITimeclockQuickbooksEmployeeModel {
	userId: number;
	userFullName: string;
	payPeriodId: number;
	timeclockEntriesAwaitingApproval: number;
	approvedBy: string;
	approvedDate: Date;
	syncedToQuickbooks: boolean
	week1RegularPayTotalHours: number;
	week2RegularPayTotalHours: number;
	week1OvertimePayTotalHours: number;
	week2OvertimePayTotalHours: number;
	week1Totals: number[];
	week2Totals: number[];
	week1Total: number;
	week2Total: number;

	timeclockQuickbooksWeek1Details: ITimeclockQuickbooksEmployeeDetailModel[];
	timeclockQuickbooksWeek2Details: ITimeclockQuickbooksEmployeeDetailModel[];		
}

