export interface IExceptionLogModel {
	exceptionLogId: number;
	userId?: number;
	userName: string;
	application: string;
	platform: string;
	version: string;
	dateTime: Date;
	url: string;
	statusCode?: number;
	message: string;
	stackTrace: string;
	jsonModel: string;
	fullErrorObj: string;
}