export interface IQBWCTaskGridViewModel {
	qbwcTaskId: number;
	displayName: string;
	ticketId: string;
	request: string;
	requestedBy: string;
	requestDate?: Date;
	requestId: string;
	requestSentDate?: Date;
	responseReceivedDate?: Date;
	status: string;
	errorMessage: string;
	isPending: boolean;
	isHighPriority: boolean;
	customDisplayInfo: string;
	displayErrorNotification: boolean
}
