export interface IOnlinePaymentInitModel {
	useAdvancedTax: boolean;
	downPaymentType: number;
	onlinePaymentShowSummaryOnly: boolean;
	onlinePortalNote: string;
	allowTips: boolean;
	useBambora: boolean;
	usePayTrace: boolean;
	useUSAePay: boolean;
	useZift: boolean;
}