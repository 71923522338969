export interface ITextChatConversationSummaryModel {
    textChatConversationId: number;
    uuid: string;
    appointmentId?: number;
    locationId?: number;
    customerId?: number;
    customerFullName: string;
    customerPhoneNumber: string;
    isAssigned: boolean;
    assignedToUserId?: number;
    assignedToUserFullName: string;
    lastTextSentDate?: Date;
    lastTextBody: string;
    unreadMessages: number;
    isClosed: boolean;
    closeDate?: Date;
    closedBy: string;
    attendantUserIds: number[];
    attendantFullNames: string;
}
