export interface IInventoryAdjustmentModel {
	inventoryAdjustmentId: number;
	itemId: number;
	inventoryAdjustmentTypeId: number;
	inventoryWarehouseId: number;
	adjustedBy: string;
	adjustmentDate: Date;
	quantity: number;
	notes: string;
	isAllocation: boolean;
	isCurrentCycleCount: boolean;
}