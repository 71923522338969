export interface IVendorListModel {
	vendorId: number;
	displayName: string;
	fullName: string;
	address1: string;
	cityStateZip: string;
	phone: string;
	emailAddress: string;
}

