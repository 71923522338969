export interface IScheduleAppointmentSearchModel {
	appointmentId: number;
	scheduledDateTime: Date;
	appointmentStatusDescription: string;
	appointmentTypeDescription: string;
	jobName: string;
	jobSiteCompanyName: string;
	jobSiteFullName: string;
	jobSiteAddress1: string;
	jobSiteAddress2: string;
	jobSiteDisplayAddress: string;
	billToCompanyName: string;
	billToFullName: string;
	billToAddress1: string;
	billToAddress2: string;
	billToDisplayAddress: string;
	modelType: string;
}