import { Component, ViewChild, Output, Input, EventEmitter, SimpleChanges } from "@angular/core";
import { SlickDialogComponent, SlickScreenBlockerService } from "@slick-components";
import { ICustomerModel, ICreditCardPaymentTypeModel, CreditCardPaymentTypeModel } from "@models";
import { GlobalsService, PaymentsService } from "@services/services.module";
import { CreditCardEditDialogComponent } from "@components/credit-card-dialog/credit-card-edit-dialog/credit-card-edit-dialog.component";
import { CustomerEditLayoutComponent } from "@app/customers/customer-components/customer-edit/customer-edit-layout/customer-edit-layout.component";
import { CreditCardService } from "@services";

@Component({
	selector: "card-edit",
	templateUrl: "card-edit.component.html",
	styleUrls: ["card-edit.component.scss"],
	providers: [SlickScreenBlockerService, CreditCardService, PaymentsService]
})

export class CardEditComponent {
	@Output() onPaymentTypesChanged: EventEmitter<ICreditCardPaymentTypeModel[]> = new EventEmitter();

	@ViewChild("creditCardDialogRef", { static: true }) creditCardDialogRef: SlickDialogComponent;
	@ViewChild("creditCardEditDialog", { static: true }) creditCardEditDialog: CreditCardEditDialogComponent;

	@ViewChild("customerEditLayoutRef") customerEditLayoutRef: CustomerEditLayoutComponent;

	@Input() customerModel: ICustomerModel;

	selectedPaymentTypeModel: ICreditCardPaymentTypeModel;
	isCanada = (GlobalsService.company.companyId === 3);

	creditCardPaymentTypes: ICreditCardPaymentTypeModel[];

	constructor(private paymentsService: PaymentsService,
		private creditCardService: CreditCardService) {
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (this.customerModel) {
			this.creditCardPaymentTypes = await this.creditCardService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);
			if (this.creditCardPaymentTypes && this.creditCardPaymentTypes.length > 0)
				this.selectPaymentType(this.creditCardPaymentTypes[0]);

			this.onPaymentTypesChanged.emit(this.creditCardPaymentTypes);
		}
	}

	async openDialog() {

		this.creditCardPaymentTypes = null;
		this.selectedPaymentTypeModel = null;
		if (this.customerModel)
			this.creditCardPaymentTypes = await this.creditCardService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);
		if (this.creditCardPaymentTypes.length > 0) {
			this.selectedPaymentTypeModel = this.creditCardPaymentTypes.find(x => x.isDefault === true);
			if (!this.selectedPaymentTypeModel)
				this.selectedPaymentTypeModel = this.creditCardPaymentTypes[0];
		}
		this.creditCardDialogRef.showDialog();
	}

	async creditCardEditDialogChanges() {
		// Something has changed
		this.creditCardPaymentTypes = await this.creditCardService.getCreditCardPaymentTypeForCustomer(this.customerModel.customerId);
		if (this.creditCardPaymentTypes && this.creditCardPaymentTypes.length > 0)
			this.selectPaymentType(this.creditCardPaymentTypes[0]);
	}

	createNewPaymentType() {
		const paymentType = new CreditCardPaymentTypeModel();
		paymentType.addressModel.address1 = this.customerModel.address1
		paymentType.addressModel.address2 = this.customerModel.address2
		paymentType.addressModel.city = this.customerModel.city
		paymentType.addressModel.state = this.customerModel.state
		paymentType.addressModel.zipcode = this.customerModel.zipcode
		paymentType.addressModel.country = this.customerModel.country
		paymentType.customerId = this.customerModel.customerId;
		this.creditCardEditDialog.openDialog(paymentType, true);
	}

	selectPaymentType(paymentType: ICreditCardPaymentTypeModel) {
		this.selectedPaymentTypeModel = paymentType;
	}

	editPaymentType(paymentType: ICreditCardPaymentTypeModel) {


		this.creditCardEditDialog.openDialog(paymentType, false);
	}

}