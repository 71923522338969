export interface IPurchaseOrderGridModel {
	purchaseOrderId: number;
	purchaseOrderNumber: string;
	purchaseOrderStatus: string;
	submittedDate?: Date;
	expectedReceivedDate?: Date;
	actualReceivedDate?: Date;
	proNumber: string;
	vendorId?: number;
	vendorCompanyName: string;
	vendorFirstName: string;
	vendorLastName: string;
	vendorDisplayName: string;
	vendorFullName: string;
	vendorAddress1: string;
	vendorAddress2: string;
	vendorCity: string;
	vendorState: string;
	vendorZipcode: string;
	vendorCountry: string;
	vendorDisplayAddress: string;
	vendorPhone: string;
	vendorPhoneAlt1: string;
	vendorPhoneAlt2: string;
	vendorEmail: string;
	quickbooksSyncStatus: string;
	sentFromWarehouseId: number;
	sentFromWarehouseAddress1: string;
	sentFromWarehouseCity: string;
	sentFromWarehouseState: string;
	sentFromWarehouseZipcode: string;
	sentFromWarehousePhoneNumber: string;
	sentFromWarehouseName: string;
	sentFromWarehouseContactName: string;
}

