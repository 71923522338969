import { ITimeClockDailyNotesModel } from "./timeclock-daily-notes.model";
import { ITimeclockEntryModel } from "./timeclock-entry.model";

export interface ITimeclockDailySummaryModel {
	summaryDate: Date;
	totalMinutes: number;
	totalHours: number;
	totalDisplay: string;
	totalBreakMinutes: number;
	totalBreakHours: number;
	totalBreakDisplay: string;
	totalWorkedMinutes: number;
	totalWorkedHours: number;
	totalWorkedDisplay: string;
	regularPayTotalMinutes: number;
	regularPayTotalDisplay: string;
	overtimePayTotalMinutes: number;
	overtimePayTotalDisplay: string;
	ptoTotalMinutes: number;
	ptoTotalDisplay: string;
	holidayTotalMinutes: number;
	holidayTotalDisplay: string;
	locked: boolean;

	timeclockEntries: ITimeclockEntryModel[];
	timeClockDailyNotesModel: ITimeClockDailyNotesModel;
}

