export interface ICustomerGridModel {
    customerId: number;
    companyId: number;
    customerLinkId: number;
    isChildCompanyCustomer: boolean;
    owningCompanyName: string;
    companyName: string;
    fullName: string;
    displayName: string;
    email: string;
    homePhone: string;
    cellPhone: string;
    workPhone: string;
    quickbooksId: string;
    syncToQuickbooks?: boolean;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    customerTypeName: string;
    isDeleted: boolean;
}

