export interface ILayoutSettings {
    id: number;
	scheduleLayout: string;
	estimatesLayout: string;
	salesOrdersLayout: string;
	lathamOrdersLayout: string;
    invoicesLayout: string;
	paymentsLayout: string;
	purchaseOrdersLayout: string;
	receivingLayout: string;
    customersLayout: string;
	jobsLayout: string;
	recurringLayout: string;
	recurringBillingLayout: string;
	inventoryLayout: string;
    smartlistLayout: string;
	timeclockLayout: string;
	timeclockDetailLayout: string;
    usersLayout: string;
	quickbooksLayout: string;
	vendorsLayout: string;
	showAppointmentBorder: boolean;
	chatLayout: string;
	shippingLayout: string;
}

