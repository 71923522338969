export interface IDailyPreChecklistLineItemModel {
	dailyPreChecklistLineItemId: number;
	appointmentId?: number;
	jobSiteName: string;
	sku: string;
	dscription: string;
	quantity: number;
	instructional: string;
	checked: boolean;
	checkedDate?: Date;
	checkedBy: string;
	saving: boolean;
}