export interface ICustomerDisplayListModel {
    customerId: number;
    owningCompanyId: number;
    owningCompanyName: string;
    clonedCustomerId: number;
    customerTypeName: string;
    password: string;
    firstName: string;
    lastName: string;
    companyName: string;
    fullName: string;
    displayName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    email: string;
    homePhone: string;
    cellPhone: string;
    workPhone: string;
    quickbooksId: string;
    displayPreviousAppointments: boolean;
    displayUpcomingAppointments: boolean;
    nextAppointment: ICustomerDisplayListAppointmentModel;
    previousAppointments: ICustomerDisplayListAppointmentModel[];
    upcomingAppointments: ICustomerDisplayListAppointmentModel[];
    upcomingAppointmentsLength: number;
}

export interface ICustomerDisplayListAppointmentModel {
    jobId: number;
    appointmentId: number;
    sequence: number;
    scheduledDateTime: Date;
    scheduledMinutes: number;
    appointmentTypeName: string;
}

