export interface ICustomerJobHistoryModel {
	jobName: string;
	jobTypeDisplayName: string;
	jobDescription: string;
	jobNotes: string;
	jobId: number;
	scheduledDateTime: Date;
	appointmentTypeDescription: string;
	appointmentDescription: string;
	appointmentNotes: string;
	appointmentCustomerNotes: string;
	resourceName: string;
	billingDisplayName: string;
	atJobsiteTotalDisplay: string;
	finishedTotalDisplay: string;
	inTransitTotalDisplay: string;
	actualCrew: string;
	crewDisplay: string;
	invoiceTotal: number;
	invoiceNumber: string;
	invoiceId: number;
	jobSiteDisplayName: string;
	jobSiteAddress1: string;
	jobSiteAddress2: string;
	// jobSiteCityStateZip: string;

}

