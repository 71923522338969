import { IAppointmentSmartlistQuestionModel } from "./appointment-smartlist-question.model";

export interface ISmartlistAppointmentModel {
	jobId: number;
	sequence: number;
	appointmentId: number;
	appointmentTypeId: number;
	appointmentTypeName: string;
	jobName: string;
	jobTypeId: number;
	jobTypeName: string;
	jobCustomerName: string;
	jobCustomerState: string;
	jobCustomerCreditHold: boolean;
	billToCustomerName: string;
	billingCustomerCreditHold: string;
	jobDescription: string;
	scheduledDateTime: Date;
	appointmentRowClass: string;
	smartlistFutureAppt: boolean;
	smartlistDelinquent: boolean;
	allRequiredChecked: boolean;
	
	smartlistQuestions: IAppointmentSmartlistQuestionModel[];
}
