export interface IJobGridModel {
    jobId: number;
    jobName: string;
    jobTypeId: number;
    jobTypeDescription: string;
    jobStatusId: number;
    jobStatusDescription: string;
    jobsiteCompanyName: string;
    jobsiteFirstName: string;
    jobsiteLastName: string;
    jobsiteFullName: string;
    jobsiteDisplayName: string;
    jobsiteAddress1: string;
    jobsiteAddress2: string;
    jobsiteCity: string;
    jobsiteState: string;
    jobsiteZipcode: string;
    jobsiteCountry: string;
    jobsiteCityStateZip: string;
    jobsiteFullAddress: string;
    jobSiteEmail: string;
    jobSiteCellPhone: string;
    billToCompanyName: string;
    billToFirstName: string;
    billToLastName: string;
    billToFullName: string;
    billToDisplayName: string;
    billToAddress1: string;
    billToAddress2: string;
    billToCity: string;
    billToState: string;
    billToZipcode: string;
    billToCountry: string;
    billToCityStateZip: string;
    billToFullAddress: string;
    billToEmail: string;
    billToCellPhone: string;
    apptType: string;
    apptStatus: string;
    resourceName: string;
    travelMinutesDisplay: string;
    goBack: boolean;
    createdBy: string;
    finishedByUserName: string;
    lastAppointmentDate?: Date;
    nextAppointmentDate?: Date;
    netIncome: number;
}
