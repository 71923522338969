export interface ICustomerListModel {
    customerId: number;
    customerMultipleAddressId: number;
    companyId: number;
    customerLinkId: number;
    isChildCompanyCustomer: boolean;
    owningCompanyName: string;
    customerCompanyName: string;
    customerFullName: string;
    customerDisplayName: string;
    multiDescription: string;
    multiCompanyName: string;
    multiFullName: string;
    multiDisplayName: string;
    email: string;
    homePhone: string;
    cellPhone: string;
    workPhone: string;
    quickbooksId: string;
    syncToQuickbooks?: boolean;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    cityStateZip: string;
    customerTypeName: string;
    isDeleted: boolean;
}

