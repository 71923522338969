
export interface IQuoteTrackingModel {
    quoteDate: Date;
    invoiceNumber: string;
    invoiceId: number;
    salesRep: string;
    createdBy: string;
    status: string;
    details: string;
    reason: string;
    notes: string;
}