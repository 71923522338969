export interface IVendorGridModel {
	vendorId: number;
	companyName: string;
	firstName: string;
	lastName: string;
	fullName: string;
	displayName: string;
	displayAddress: string;
	address1: string;
	address2: string;
	city: string;
	state: string;
	zipcode: string;
	country: string;
	phone: string;
	phoneAlt1: string;
	phoneAlt2: string;
	emailAddress: string;
	quickbooksSyncStatus: string;
}
